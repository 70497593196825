
import {getCookie} from '@/utils/dw'
import Vue from 'vue'
export default Vue.extend({
  props: {
    url: {
      type: String,
      default: '/pub/v1.uploadOSS/uploadImg',
    },
    name: {
      type: String,
      default: 'file',
    },
    value: {
      type: String,
      default: '',
    },
    removable: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: '148px',
    },
    module: {
      type: String,
    },
    business: {
      type: String,
    },
  },
  data() {
    return {
      dialogVisible: false,
      uploadImgUrl: `${process.env.VUE_APP_BASE_API}${this.url}`, // 上传的图片服务器地址
      headers: {AdminToken: getCookie('token')},
      childValue: this.value,
      // imageUrl:""
    }
  },
  computed: {
    style() {
      return `width: ${this.size}; height: ${this.size}; line-height: ${this.size}`
    },
    data() {
      return this.module && this.business ? {module: this.module, business: this.business} : undefined
    },
  },
  watch: {
    value: {
      handler(cur) {
        if (cur === '') {
          this.childValue = ''
        } else {
          if (!cur.startsWith('blob')) {
            this.childValue = this.$formatImg(cur)
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    removeImage() {
      this.$emit('input', '')
      this.$emit('getUploadData', {})
      this.childValue = ''
    },
    handleUploadSuccess(res: any) {
      if (res.code === 1) {
        this.$message.success('上传成功')
        this.$emit('input', res.data.url)
        this.$emit('getUploadData', res.data)
      } else {
        this.$message.error(res.msg)
      }
      this.$Loading().close()
    },
    handleBeforeUpload({name, size}: {name: string; size: number}) {
      const suffix = name.substring(name.lastIndexOf('.') + 1)
      const suffixList = ['jpeg', 'gif', 'png', 'bmp', 'jpg']
      if (!suffixList.includes(suffix.toLowerCase())) {
        this.$message.error('上传文件格式错误，只允许上传jpg、jpeg、gif、png、bmp格式文件！')
        return false
      }
      if (size / 1024 / 1024 > 10) {
        this.$message.error('上传文件不允许大于10M！')
        return false
      }
      this.$Loading('上传中')
    },
    handleUploadError() {
      this.$message.error('上传失败,请重新上传！')
      this.$Loading().close()
    },
  },
})
