export function isExternal(path: string) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 * 判断输入的字符串是否为有效的手机号码,支持中国大陆、香港、澳门、台湾的手机号码格式
 * @param {string} phone - 待验证的手机号码
 * @returns {boolean} - 如果是有效的手机号码，返回true；否则返回false
 */
export const isPhone = (phone: string) => {
  return /^(?:(?:\+?86)?(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}|(?:\+?852\s)?[569]\d{7}|(?:\+?853\s)?6\d{7}|(?:\+?886\s)?09\d{8})$/.test(
    phone,
  )
}
